import AdminLogin from "pages/admin/AdminLogin/AdminLogin";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";

const useAuth = () => {
    const isAdminLoggedIn = useSelector((state) => state.adminAuthReducer.isLoggedIn);
    return isAdminLoggedIn;
};

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <AdminLogin />;
};
export default ProtectedRoutes;
