import { Button, Checkbox, Form, Input, Modal, Radio, Upload } from "antd";
import axios from "axios";
import { httpClient } from "config/config";
import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classes from "./addText.module.css";

export default function TextModal({
    isVisible,
    setIsVisible,
    getTexts,
    editData,
    editMode,
    setEditMode,
}) {
    const [type, setType] = useState(true);
    const [text, setText] = useState();

    function encodeImageFileAsURL(element) {
        var file = element.file;
        var reader = new FileReader();
        reader.onloadend = function () {
            setText(reader.result);
        };
        reader.readAsDataURL(file);
    }
    useEffect(() => {
        if (editMode) {
            setType(editData?.isText ? true : false);
            setText(editData?.textContent?.textContent);

            httpClient("textQuestions/" + editData.id).then((res) => setText(res.data.textContent));
        }
    }, [editMode]);

    const handleForm = () => {
        const data = { status: true, textContent: text, isText: type };
        if (editMode) {
            httpClient("textQuestions/" + editData.key, "put", data).then(() => {
                setText();
                onCancel();
                getTexts();
            });
        } else {
            httpClient("textQuestions", "post", data).then(() => {
                setText();
                onCancel();
                getTexts();
            });
        }
    };

    const onCancel = () => {
        setIsVisible(false);
        setEditMode(false);
        setText();
    };

    return (
        <div>
            <Modal
                title="Text əlavə et"
                open={isVisible}
                footer={null}
                style={{ height: "200px" }}
                width={"60%"}
                onCancel={onCancel}
                destroyOnClose={true}
            >
                <div className="add_text_modal">
                    <Form style={{ width: "100%" }}>
                        <Form.Item>
                            <Form.Item>
                                <Radio.Group
                                    defaultValue={type}
                                    value={type}
                                    onChange={(e) => {
                                        setType(e.target.value);
                                        setText();
                                    }}
                                >
                                    <Radio value={false}>Şəkil</Radio>
                                    <Radio value={true}>Mətn</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {type ? (
                                // <Input.TextArea
                                //     style={{ height: "200px" }}
                                //     className={classes.uploadArea}
                                //     onChange={(e) => setText(e.target.value)}
                                //     value={text}
                                // ></Input.TextArea>
                                <ReactQuill
                                    matchVisual={false}
                                    theme="snow"
                                    value={text}
                                    onChange={(e) => {
                                        setText(e);
                                    }}
                                    className="custom_quil_heading"
                                />
                            ) : (
                                <Upload
                                    beforeUpload={() => false}
                                    listType="text"
                                    onChange={encodeImageFileAsURL}
                                    showUploadList={false}
                                >
                                    {text ? (
                                        <img src={text} alt="avatar" style={{ width: "100%" }} />
                                    ) : (
                                        <div className={classes.uploadButton}>Yüklə</div>
                                    )}
                                </Upload>
                            )}
                        </Form.Item>
                    </Form>
                    <Button disabled={!text} onClick={handleForm}>
                        Əlavə et
                    </Button>
                </div>
            </Modal>
        </div>
    );
}
