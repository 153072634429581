import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { httpClient } from "config/config";
import ShowResult from "./ShowResult";
import classes from "./checkAnswers.module.css";

export default function Report() {
    const [sections, setSections] = useState([]);
    const [modules, setModules] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [selectedSection, setSelectedSection] = useState();

    const [isVisible, setIsVisible] = useState(false);
    const [reportData, setReportData] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        const getSections = httpClient("sections", "get");
        const getQuiz = httpClient("quizs", "get");
        Promise.all([getSections, getQuiz]).then((res) => {
            setSections(res[0].data);
            setQuizzes(res[1].data);
        });
    }, []);

    const onFinish = (value) => {
        navigate(
            `/admin/check-answers?quizId=${value.quizId}&moduleId=${value.moduleId}&promoCode=${value.promoCode}`
        );
    };

    const onFinishResult = (value) => {
        httpClient(`report/userAnswer/${value.quizId2}/${value.promoCode2}`).then((res) => {
            setReportData(res?.data);
            setIsVisible(true);
        });
    };

    return (
        <div className={classes.report}>
            <Row gutter={80}>
                <Col span={12}>
                    <Form layout="vertical" onFinish={onFinish} className={classes.select_box_form}>
                        <h3>Suallar və cavablara bax</h3>
                        <Form.Item
                            label="Section seç"
                            name="sectionId"
                            rules={[
                                {
                                    required: true,
                                    message: "Section seçin",
                                },
                            ]}
                        >
                            <Select
                                onChange={(e) =>
                                    httpClient("sections/" + e).then((res) => {
                                        setModules(res?.data?.modules);
                                        setSelectedSection(e);
                                    })
                                }
                            >
                                {sections.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Modul seç"
                            name="moduleId"
                            rules={[
                                {
                                    required: true,
                                    message: "Modul seçin",
                                },
                            ]}
                        >
                            <Select disabled={!selectedSection}>
                                {modules.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Quiz seç"
                            name="quizId"
                            rules={[
                                {
                                    required: true,
                                    message: "Quiz seçin",
                                },
                            ]}
                        >
                            <Select>
                                {quizzes.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Promokod"
                            name="promoCode"
                            rules={[
                                {
                                    required: true,
                                    message: "Promokodu daxil edin",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Button htmlType="submit">Daxil ol</Button>
                    </Form>
                </Col>
                <Col span={12}>
                    <Form
                        layout="vertical"
                        onFinish={onFinishResult}
                        className={classes.select_box_form}
                    >
                        <h3>Nəticəyə bax</h3>
                        <Form.Item
                            label="Quiz seç"
                            name="quizId2"
                            rules={[
                                {
                                    required: true,
                                    message: "Quiz seçin",
                                },
                            ]}
                        >
                            <Select>
                                {quizzes.map((item) => (
                                    <Select.Option value={item.id} key={item.id}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Promokod"
                            name="promoCode2"
                            rules={[
                                {
                                    required: true,
                                    message: "Promokodu daxil edin",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Button htmlType="submit">Bax</Button>
                    </Form>
                </Col>
            </Row>

            <ShowResult isVisible={isVisible} setIsVisible={setIsVisible} data={reportData} />
        </div>
    );
}
