import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editQuiz:null,
    editModule:null
};

const EditQuizSlice = createSlice({
    name: "quizModule",
    initialState,
    reducers: {
        editQuizId: (state,action) => {
            state.editQuiz = action.payload.editQuiz;
        },
        editModuleId: (state,action) => {
            state.editModule = action.payload.editModule;
        },
    },
});

export const { editQuizId, editModuleId } = EditQuizSlice.actions;
export default EditQuizSlice.reducer;
