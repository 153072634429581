import { useEffect, useState } from "react";
import { Button, DatePicker, Popconfirm, Table, TimePicker } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { httpClient } from "config/config";
import classes from "./promocodes.module.css";
import dayjs from "dayjs";

export default function Promocodes() {
    const [promocodes, setPromocodes] = useState([]);
    const [ready, setready] = useState([]);
    const [selectedCode, setSelectedCode] = useState([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [render, setRender] = useState(false);

    const getPromocodes = () => {
        httpClient("promoCodes/list/false").then((res) => setPromocodes(res.data));
        httpClient("promoCodes/list/true").then((res) => setready(res.data));
    };

    useEffect(() => {
        getPromocodes();
    }, []);

    const handleSelectPromokod = (data) => {
        let oldState = selectedCode;
        let check = true;
        oldState.map((item) => {
            if (item.id == data.id) {
                check = false;
            }
        });
        if (check) {
            oldState.push(data);
            setSelectedCode(oldState);
            setRender(!render);
        }
    };

    const handleDelete = (id) => {
        httpClient("promoCodes/" + id, "delete").then((res) =>
            httpClient("promoCodes/list/true").then((res) => setready(res.data))
        );
    };

    const column = [
        {
            title: "Promokod",
            dataIndex: "promoCode",
            width: "60%",
        },
        {
            title: "Əməliyyat",
            width: "40%",

            render: (data) => {
                return (
                    <>
                        <Button
                            onClick={() => {
                                handleSelectPromokod(data);
                            }}
                        >
                            Seç
                        </Button>
                    </>
                );
            },
        },
    ];

    const readyColumn = [
        {
            title: "Promokod",
            dataIndex: "promoCode",
            width: "40%",
        },
        {
            title: "Başlama tarixi",
            dataIndex: "startDate",
            width: "40%",
        },
        {
            title: "Bitmə tarixi",
            dataIndex: "endDate",
            width: "40%",
        },
        {
            title: "Əməliyyat",
            width: "30%",
            render: (data) => {
                return (
                    <>
                        {/* <Button onClick={console.log(data)}>
                            <EditOutlined />
                        </Button> */}

                        <Popconfirm
                            title="Promokod silisin?"
                            onConfirm={() => handleDelete(data?.id)}
                        >
                            <Button>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </>
                );
            },
        },
    ];

    const tableData = promocodes.map((item) => {
        return {
            id: item.id,
            key: item.id,
            promoCode: item?.promoCode,
        };
    });
    const readyData = ready.map((item) => {
        return {
            id: item.id,
            key: item.id,
            promoCode: item?.promoCode,
            startDate: dayjs(item.startDate).format("YYYY-MM-DD HH:mm:ss"),
            endDate: dayjs(item.endDate).format("YYYY-MM-DD HH:mm:ss"),
        };
    });

    const handleSubmit = () => {
        if (startDate && endDate && selectedCode.length > 0) {
            let promoCodeIds = [];
            selectedCode.map((item) => promoCodeIds.push(item.id));
            httpClient(`promoCodes/addDate`, "put", {
                startDate: startDate,
                endDate: endDate,
                promoCodeIds,
            }).then(() => {
                setSelectedCode([]);
                getPromocodes();
            });
        }
    };
    const handleRemove = (id) => {
        let oldState = selectedCode;
        let newState = oldState.filter((item) => item.id != id);
        setSelectedCode(newState);
        setRender(!render);
    };

    return (
        <div className={classes.tables}>
            <Table
                pagination={false}
                dataSource={readyData}
                columns={readyColumn}
                scroll={{ y: "400px" }}
                style={{ maxWidth: "500px" }}
            />

            <div className={classes.mid}>
                <div className={classes.mid_buttons}>
                    <DatePicker.RangePicker
                        showTime={{
                            hideDisabledOptions: true,
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                        onChange={(e) => {
                            if (e) {
                                setStartDate(dayjs(e[0])?.toISOString());
                                setEndDate(dayjs(e[1])?.toISOString());
                            }
                        }}
                    />

                    <Button
                        onClick={handleSubmit}
                        type="primary"
                        disabled={!(selectedCode.length > 0 && startDate && endDate)}
                    >
                        Əlavə et
                    </Button>
                </div>

                <div className={classes.mid_list}>
                    <p>Seçilmiş Promokodlar:</p>
                    <div className={classes.mid_list_area}>
                        {selectedCode.map((item) => (
                            <div key={item.id}>
                                <span>{item.promoCode}</span>
                                <span
                                    className={classes.remove}
                                    onClick={() => handleRemove(item.id)}
                                >
                                    X
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Table
                pagination={false}
                dataSource={tableData}
                columns={column}
                scroll={{ y: "400px" }}
                style={{ maxWidth: "300px" }}
            />
        </div>
    );
}
